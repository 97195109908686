<template>
  <div>
    <v-container
      class="v-container-fluid"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <div class="mt-5">
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <p class="text-title mon-bold">
                  {{ texts.vendor.textTitle }}
                </p>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <div class="display-flex align-items-center justify-content-flex-end height-100">
                  <v-text-field
                    v-model="sSearch"
                    solo
                    :label="texts.vendor.textSearch"
                    append-icon="mdi-magnify"
                    class="global-inputs mon-regular mod-input"
                    outlined
                    color="#000000"
                    dense
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "VendorSearchbarLayout",
  data() {
    return {
      //VARIABLES
      sSearch: "",
    };
  },
  beforeMount() {
    this.$store.commit("setSearch", "");
  },
  methods: {},
  computed: {},
  watch: {
    // sSearch: function () {
    //   this.$store.commit("setSearch", this.sSearch);
    // },
    sSearch: lodash.debounce(function (val) {
      this.$store.commit("setSearch", this.sSearch);
    }, 500),
  },
};
</script>

<style>
.mod-input .v-input__slot {
  padding-right: 0px !important;
}

.mod-input .v-input__icon {
  background: transparent linear-gradient(228deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  height: 40px;
  width: 40px;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}
</style>

<style scoped>
.text-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}
</style>